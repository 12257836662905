// window.bootstrap = require("./bootstrap");
window.moment = require("moment");

import { Chart, initMDB } from "mdb-ui-kit/js/chart.es.min.js";

import { Tab, Collapse, Tooltip, Modal } from "mdb-ui-kit/js/mdb.es.min.js";

initMDB({ Collapse, Tab, Tooltip, Modal, Chart });

//mobile accordion instead of tabs
$("body").on("click", ".accordion-body .dropdownItem", function (event) {
    event.preventDefault();
    var target = $(this).data("tab");

    var targetTabEl = document.querySelector("#" + target + " button");
    var targetTab = new bootstrap.Tab(targetTabEl);

    targetTab.show();

    //change current dropdown and close accordion
    var text = $(this).find("div")[0].innerHTML;
    console.log(text);

    $("#mobileAccordionNav .text").empty();
    $("#mobileAccordionNav .text").append(text);

    // $('#tabAccordion').collapse('hide')
    $("#tabAccordion").find(".collapse").removeClass("show");
    $("#mobileAccordionNav").addClass("collapsed");
});

// Custom MDBootstrap initializations
//-- Line chart
const dataLineChart1 = {
    type: "line",
    data: {
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
        datasets: [
            {
                borderColor: "#D30934",
                borderWidth: "3",
                fill: false,
                pointBackgroundColor: "#D30934",
                pointBorderColor: "#D30934",
                pointBorderWidth: "2",
                data: [42, 28, 56, 40, 28, 40, 50, 45, 58, 70, 80, 75],
            },
        ],
    },
};
const optionsLineChart1 = {
    options: {
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
        },
    },
};
new Chart(
    document.getElementById("lineChart1"),
    dataLineChart1,
    optionsLineChart1
);

//-- Vertical bar chart
const dataBarChartVertical1 = {
    type: "bar",
    data: {
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
        datasets: [
            {
                backgroundColor: "#95C11F",
                data: [42, 28, 56, 40, 28, 40, 50, 45, 58, 70, 80, 75],
            },
        ],
    },
};
const optionsBarChartVertical1 = {
    options: {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
        },
    },
};
new Chart(
    document.getElementById("barChartVertical1"),
    dataBarChartVertical1,
    optionsBarChartVertical1
);

//-- Horizontal bar chart
const dataBarChartHorizontal1 = {
    type: "bar",
    data: {
        labels: ["A", "B", "C", "D", "E", "F", "G", "H"],
        datasets: [
            {
                labelColor: "#57555B",
                label: "Label",
                backgroundColor: "#007B88",
                data: [42, 28, 56, 40, 28, 40, 50, 45, 58, 70, 80, 75],
            },
        ],
    },
};
const optionsBarChartHorizontal1 = {
    options: {
        indexAxis: "y", // this makes this bar chart horizontal
        responsive: true,
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
            labels: {
                color: "rgb(255, 0, 0)",
            },
        },
    },
};
new Chart(
    document.getElementById("barChartHorizontal1"),
    dataBarChartHorizontal1,
    optionsBarChartHorizontal1
);
